import React from 'react';
import AcDetailsCard from '@components/ac-details-card/ac-details-card.web';
import { TITLES } from '@constants';
import { COUNTRIES_LIST } from '@data/countries.data';

export const AcCompanyDetails = ({
  loading = false,
  editCompanyCallback,
  company,
}) => {
  const result = [
    {
      label: 'Name',
      value: (company && company.name) || '-',
    },
    {
      label: 'Address',
      value:
        (company &&
          `${company.address_line_1 ? `${company.address_line_1}<br>` : ''} 
          ${company.address_line_2 ? `${company.address_line_2}<br>` : ''} 
          ${company.address_line_3 ? `${company.address_line_3}<br>` : ''}
          ${company.postal_code} ${company.city}`) ||
        '-',
    },
    {
      label: 'Country',
      value: (company && COUNTRIES_LIST[company.country_code]) || '-',
    },
    {
      label: 'Website',
      value:
        (company &&
          company.website &&
          `<a href="${company.website}" target="_blank"><span>${company.website}</span><i class="ac-icon ac-icon--open-in-new" /></a>`) ||
        '-',
    },
    {
      label: 'Phone number',
      value: (company && company.phone) || '-',
    },
  ];

  return (
    <AcDetailsCard title={TITLES.MY_COMPANY} items={result} loading={loading} />
  );
};
